//import '@/js/index';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import AsyncComputed from 'vue-async-computed';
import {ServerTable, ClientTable} from 'v-tables-3';
import GlobalNotification from './components/tools/GlobalNotification.vue'
import '@/assets/css/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const app = createApp(App);

app.config.productionTip = false;
app.use(router);
app.use(store); 
app.use(AsyncComputed)
app.use(ClientTable, {
    theme: 'bootstrap5',
    pagination: {
        chunk: 5,
        nav: 'fixed'
    }
})
app.use(ServerTable, {
    theme: 'bootstrap5',
    pagination: {
        chunk: 5,
        nav: 'fixed'
    }
})
app.component('global-notification', GlobalNotification)

app.mount('#app');
