//import Vue from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import Index from './components/Index.vue';
import FIT from './components/pages/FIT.vue';
import PCM from './components/pages/PCM.vue';
import PCMCost from './components/pages/PCMCost.vue';
import Login from './components/pages/Login.vue';
import Logout from './components/pages/Logout.vue';
import Users from '@/components/pages/Users.vue';
import User from '@/components/pages/User.vue';
import store from './store';
import AccessGroups from '@/components/pages/AccessGroups.vue';
import AccessGroup from '@/components/pages/AccessGroup.vue';
import Languages from '@/components/pages/Languages.vue';
import Language from '@/components/pages/Language.vue';
import UserProfile from '@/components/pages/UserProfile.vue';
import BookingForms from '@/components/pages/BookingForms.vue';
import BookingForm from '@/components/pages/BookingForm.vue';

//Vue.use(VueRouter);
const api = window.api;

const routes = [
  { path: '/:pathMatch(.*)*', name: 'not-found', component: Index 
  },
  {
    path: '/',
    name: 'Root',
    component: Index,
  },
  {
    path: '/fit',
    name: 'FIT',
    component: FIT,
  },
  {
    path: '/pcm',
    name: 'PCM',
    component: PCM,
  },
  {
    path: '/pcmcost/:id',
    name: 'PCMCost',
    component: PCMCost,
    props: route => ({ id: route.params.id || null })
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  { 
    path: '/users', 
    name: 'users', 
    component: Users
  },
  { 
    path: '/user/:id?', 
    name: 'user', 
    component: User, 
    props: route => ({ id: route.params.id || null })
  },
  { 
    path: '/profile', 
    name: 'userProfile', 
    component: UserProfile
  },
  { 
    path: '/accessgroups', 
    name: 'accessgroups', 
    component: AccessGroups
  },
  { 
    path: '/accessgroup/:id?', 
    name: 'accessgroup', 
    component: AccessGroup, 
    props: route => ({ id: route.params.id || null })
  },
  { 
    path: '/languages', 
    name: 'languages', 
    component: Languages
  },
  { 
    path: '/language/:id?', 
    name: 'language', 
    component: Language, 
    props: route => ({ id: route.params.id || null })
  },
  { 
    path: '/bookingforms', 
    name: 'bookingforms', 
    component: BookingForms
  },
  { 
    path: '/bookingform/:id?',   
    name: 'bookingform', 
    component: BookingForm, 
    props: route => ({ id: route.params.id || null })
  },

];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async function(to, from, next) {
  let currentUser = api.currentUser;
  if (to.path == '/login') {
    if (!currentUser) {
      currentUser = await api.getCurrentUser();
      }
      if (currentUser) {
          next('/');
      } else {
          next();
      }
  } else if (to.path == '/logout') {
    next();
      return;
  } else {
    if (!currentUser) {
      let user = await api.getCurrentUser();
      if (!user) {
        next('/login');
        return;
      } else {
        store.commit('setUser', user);
      }
    }
    next();
    return;
  }
});



router.afterEach((to, from) => {
  from
  if (to.path.split('/')[1]=='public') {
      document.title = process.env.APP_NAME;
      const link = document.querySelector("[rel='icon']")
      link.setAttribute('href', to.meta.icon)
  }

})


export default router;
