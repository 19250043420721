<template>
  <div class="bookingform-list">
    <h2>Booking Form List</h2>
    <div class="d-flex w-100 justify-content-end">
      <search-box class="col-3" v-model="search"></search-box>
    </div>
    <v-client-table
      :data="filteredBookingForms"
      :columns="columns"
      :options="options"
      @row-click="showBookingForm"
    ></v-client-table>
  </div>
</template>

<script>
import SearchBox from "@/components/tools/SearchBox.vue";
const api = window.api;

export default {
  name: "BookingForms",
  components: {
    SearchBox,
  },
  data() {
    return {
      bookingforms: [],
      search: "",
      columns: ["id", "RefCode", "PaxName", "Email"],
      options: {
        headings: {
          RefCode: "Ref",
          FirstName: "First Name",
          LastName: "Last Name",
        },
        sortable: ["id", "RefCode", "PaxName", "Email"],
        filterable: ["id", "RefCode", "PaxName", "Email"],
        perPage: 10,
        perPageValues: [10, 20, 50],
        skin: "table table-striped table-hover",
      },
    };
  },
  computed: {
    filteredBookingForms() {
      if (!this.bookingforms || !Array.isArray(this.bookingforms)) {
        return [];
      }
      if (!this.search) return this.bookingforms;
      const searchLower = this.search?.toLowerCase();
      return this.bookingforms.filter((form) => {
        return (
          form.id?.toString().includes(searchLower) ||
          form.RefCode?.toLowerCase().includes(searchLower) ||
          form.PaxName?.toLowerCase().includes(searchLower) ||
          form.Email?.toLowerCase().includes(searchLower)
        );
      });
    },
  },
  mounted() {
    this.fetchBookingForms();
  },
  methods: {
    async fetchBookingForms() {
      this.bookingforms = await api.get("/api/bookingform/");
    },
    showBookingForm(data) {
      this.$router.push({ name: "bookingform", params: { id: data.row.id } });
    },
  },
};
</script>

<style scoped>
.bookingform-list {
  margin: 20px;
}
.mb-4 {
  margin-bottom: 16px;
}
</style>
