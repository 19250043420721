<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top py-0">
            <div class="toggle-sidebar">
                <!--font-awesome-icon icon="bars" @click="toggle" class="mr-3"/-->
                <span></span>
            </div>
            <img src="@/assets/img/logo.svg" width="150">
            <div class="navbar-collapse ml-3" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'FIT'})">
                            <span class="mr-2 d-none d-lg-inline">
                                FIT
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'PCM'})">
                            <span class="mr-2 d-none d-lg-inline">
                                PCM
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'users'})">
                            <span class="mr-2 d-none d-lg-inline">
                                Users
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'accessgroups'})" >
                            <span class="mr-2 d-none d-lg-inline">
                                Access Groups
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'languages'})" >
                            <span class="mr-2 d-none d-lg-inline">
                                Languages
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push({name: 'bookingforms'})" >
                            <span class="mr-2 d-none d-lg-inline">
                                Booking Forms
                            </span>
                        </a>
                    </li>

                </ul>

                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown no-arrow">
                        <a v-if="user" class="nav-link dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline"> {{ user.UserName }} </span>
                            <img class="img-profile rounded-circle" :src="user.Image.url" v-if="user.ImageId">
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item cursor-pointer" @click="$router.push({name: 'userProfile'})">
                                <!--font-awesome-icon icon="user" class="fa-sm fa-fw mr-2 text-gray-400"/-->
                                Profile
                            </a></li>
                            <div class="dropdown-divider"></div>
                            <li><a class="dropdown-item cursor-pointer" @click="$router.push({name: 'Logout'})">
                                <!--font-awesome-icon icon="sign-out-alt" class="fa-sm fa-fw mr-2 text-gray-400"/-->
                                Sign out
                            </a></li>
                        </div>
                    </li>            
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data () {
        return {
        };
    },
    computed: {
        user () {
            return this.$store.state.user;
        }
    },
    async mounted () {
    },
    methods: {
        logout () {
            this.$router.push({name: 'Logout'});
        },
        setRoute (tableName) {
            this.$router.push({ name: 'abm', params: { table: tableName }});
        }
    },
};
</script>

<style scoped>
.dropdown-toggle, .dropdown-toggle:hover, .d-lg-inline {
    color: white;
}
</style>
