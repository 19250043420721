<template>
  <div :class="{'form-material': !material}" class="search-box">
    <div class="form-group form-default my-0 mb-2" :class="addClass">
      <input 
        class="form-control" 
        type="text"  
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)" 
        :id="addId" 
        @focus="$emit('focusOn')" 
        :class="{'fill': modelValue}" 
      />
      <span class="form-bar"></span>
      <label class="float-label">
        {{ placeholder }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: {
    modelValue: String,
    addClass: String,
    material: Boolean,
    addId: String,
    label: String,
  },
  data() {
    return {
      placeholder: 'Search',
    };
  },
  mounted() {
    if (this.label) {
      this.placeholder = this.label;
    }
  },
};
</script>
